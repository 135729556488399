.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  float: left;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.MuiListItemSecondaryAction-root {
  top: 50%;
  right: 150px;
  position: absolute;
  transform: translateY(-50%);
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.rowtime {
  margin-left: -250px;
}

input.rc-time-picker-input {
  height: 39px;
  width: 100px;
  font-size: 1rem;
  color: #000;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
}

.rc-time-picker-clear {
  left: 77px !important;
  top: 6px !important;
}

.infor {
  margin-top: -29px !important;
}

.defscheduleopen {
  margin-top: -37px;
}

.rc-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.38) !important;
  background: #e8e8e8 !important;
  cursor: not-allowed;
}

li.rc-time-picker-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold;
}
li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}

/* Invoice Details Table */
.invoiceTableContainer {
  height: calc(100vh - 305px);
  overflow-y: auto;
  position: relative;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  scroll-margin: 100px;
}

.invoiceTable {
  color: rgba(0, 0, 0, 0.87);
  min-width: 100%;
  outline: none;
  font-size: 0.875rem;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01071em;
  border-collapse: separate;
  border-spacing: 0;
}

.invoiceTable th {
  position: sticky;
  background-color: white;
  top: 0px;
  border-top: 0px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-left: 0px solid rgba(224, 224, 224, 1);
  border-right: 0px solid rgba(224, 224, 224, 1);
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 5;
}

.invoiceTable th:not(:last-child):after {
  content: '';
  height: 20%;
  margin-top: 25px;
  width: 0.1em;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(224, 224, 224, 1);
}

.invoiceTable tbody {
  height: 100%;
  overflow-y: auto;
}

.invoiceTable tbody tr:last-child {
  position: sticky;
  bottom: -1px;
  background-color: white;
  z-index: 1;
}

.invoiceTable tbody tr:last-child hr {
  margin: 0;
  padding: 0;
}

.invoiceTable td,
.invoiceTable th {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-overflow: ellipsis;
}

.invoiceTable tbody tr {
  min-height: 50px;
  max-height: 50px;
}

.invoiceTable tbody td {
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 0px solid rgba(224, 224, 224, 1);
  border-left: 0px solid rgba(224, 224, 224, 1);
  border-right: 0px solid rgba(224, 224, 224, 1);
}

.invoiceTable tbody tr:hover {
  background-color: rgb(241, 241, 246);
}

#language {
  position: relative;
}

#language_options {
  display: none;
  position: absolute;
  left: -150px;
  top: 0px;
  background-color: white;
  border: 2px solid #1e4ca1;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

#language:hover #language_options {
  display: block;
}
